<template>
  <v-app>
    <v-main>
      <v-container fluid class="px-15">
        <v-row>
          <v-col cols="12">
            <v-card elevation="0">
              <v-card-title class="bold-title">Forwards activos</v-card-title>
              <v-card-text>Desglose de tus forwards activos</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="px-15">
        <div class="display-flex align-items-center justify-start">
          <h2 class="text-title mon-bold">
            {{ texts.status.paid }}
          </h2>
        </div>

        <v-container fluid>
          <div class="display-flex align-items-center justify-end">
            <v-btn
              class="button-add mon-bold ml-2"
              elevation="0"
              style="width: 120px"
              @click="sendToPayNowForwards()"
            >
              <v-icon left class="mr-2">mdi-currency-usd</v-icon>
              <div style="color: #000">Pagar</div>
            </v-btn>
          </div>
          <v-simple-table style="width: 100%; border: 1px solid #e8e8e8">
            <template class="full-width">
              <thead style="justify-content: center; background-color: #f2f2f4">
                <tr>
                  <th class="text-center">
                    <v-checkbox
                      v-model="bSelectedAll"
                      :input-value="bSelectedAll"
                      color="#f0be43"
                      hide-details
                      @change="toggleAllForwards"
                    ></v-checkbox>
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.purchaseDate }}
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.provider }}
                  </th>
                  <th class="text-center">{{ texts.dataTable.invoice }}</th>
                  <th class="text-center">
                    {{ texts.dataTable.totalAmount }}
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.exchangeRateProtection }}
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.status }}
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.currencyProfit }}
                  </th>
                  <!-- <th class="text-center">
                    {{ texts.dataTable.totalProtectedUsd }}
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.totalUsdToday }}
                  </th> -->
                  <th class="text-center">
                    {{ texts.dataTable.currencyGain }}
                  </th>
                  <th class="text-center">Fecha de deposito</th>
                  <th class="text-center">Ventana de pago</th>
                  <th class="text-center">{{ texts.dataTable.actions }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="exchangeRateData in exchangeRatesStateForwards"
                  :key="exchangeRateData.sExchangeRateId"
                >
                  <td class="text-center">
                    <v-checkbox
                      v-model="exchangeRateData.bSelected"
                      :input-value="exchangeRateData.bSelected"
                      :disabled="
                        isEditable(
                          exchangeRateData.paymentExchangeRate[0].dPayment_date
                        )
                      "
                      color="#f0be43"
                      hide-details
                    ></v-checkbox>
                  </td>
                  <td class="text-center">
                    {{ formatDate(exchangeRateData.dPurchaseDate) }}
                  </td>
                  <td class="text-center">
                    {{ exchangeRateData.sProviderName }}
                  </td>
                  <td class="text-center">
                    {{ exchangeRateData.sInvoiceNumber }}
                  </td>
                  <td class="text-center">
                    ${{ formatMoney(exchangeRateData.dTotalAmount) }} MXN
                  </td>
                  <td class="text-center">
                    {{ exchangeRateData.dInvoiceExchangeRateProtection }}
                    MXN
                  </td>
                  <td class="text-center">
                    {{
                      texts.status[exchangeRateData.statusName] ||
                      exchangeRateData.statusName
                    }}
                  </td>
                  <td
                    class="text-center"
                    :style="{
                      color:
                        exchangeRateData.dCurrencyProfitPercentage < 0
                          ? 'red'
                          : 'green'
                    }"
                    style="font-weight: 800"
                  >
                    {{ exchangeRateData.dCurrencyProfitPercentage }} %
                  </td>
                  <!-- <td class="text-center">
                    {{ formatMoney(exchangeRateData.dTotalProtectedUsd) }}
                    USD
                  </td>
                  <td class="text-center">
                    {{ formatMoney(exchangeRateData.dTotalUsdToday) }} USD
                  </td> -->
                  <td
                    class="text-center"
                    :style="{
                      color:
                        exchangeRateData.dCurrencyGain < 0 ? 'red' : 'green'
                    }"
                    style="font-weight: 800"
                  >
                    {{ formatMoney(exchangeRateData.dCurrencyGain) }} USD
                  </td>
                  <td class="text-center due-date">
                    {{
                      formatDate(
                        exchangeRateData.paymentExchangeRate[0].dPayment_date
                      )
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      addDays(
                        exchangeRateData.paymentExchangeRate[0].dPayment_date,
                        15
                      )
                    }}
                  </td>
                  <td class="text-center">
                    <v-btn
                      color="#f5f7f9"
                      elevation="0"
                      :disabled="
                        isEditable(
                          exchangeRateData.paymentExchangeRate[0].dPayment_date
                        )
                      "
                      @click="openModal(exchangeRateData)"
                      style="width: 24px"
                    >
                      <v-icon color="#6e808d">mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-container>

      <v-dialog
        persistent
        v-model="dialogEditProfileActive"
        :width="screenWidth + '%'"
      >
        <div class="container-dialog">
          <close-dialog-component @closeDialog="closeDialog" />
          <p class="text-title mon-bold mt-2">
            {{ texts.modalForm.textTitle }}
          </p>
          <div class="mt-9">
            <v-col>
              <v-row cols="12" md="6">
                <v-text-field
                  v-model="editableData.dPurchaseDate"
                  type="date"
                  :label="formatDate(editableData.dPurchaseDate)"
                  outlined
                  disabled
                  color="#000000"
                  dense
                  :placeholder="texts.modalForm.purchaseDate"
                  persistent-placeholder
                ></v-text-field>
                <v-text-field
                  v-model="editableData.sProviderName"
                  type="text"
                  disabled
                  :label="texts.modalForm.provider"
                  outlined
                  color="#000000"
                  dense
                  :placeholder="texts.modalForm.provider"
                  persistent-placeholder
                ></v-text-field>
              </v-row>

              <v-row cols="12" md="6">
                <v-text-field
                  v-model="editableData.sInvoiceNumber"
                  type="text"
                  :label="texts.modalForm.invoice"
                  outlined
                  disabled
                  color="#000000"
                  dense
                  :placeholder="texts.modalForm.invoice"
                  persistent-placeholder
                ></v-text-field>
                <v-text-field
                  v-model="editableData.dTotalAmount"
                  type="text"
                  :label="texts.modalForm.totalAmount"
                  outlined
                  disabled
                  color="#000000"
                  dense
                  :placeholder="texts.modalForm.totalAmount"
                  persistent-placeholder
                ></v-text-field>
              </v-row>

              <v-row cols="12" md="6">
                <v-text-field
                  v-model="editableData.iCreditPeriod"
                  type="text"
                  :label="texts.modalForm.creditPeriod"
                  outlined
                  disabled
                  color="#000000"
                  dense
                  :placeholder="texts.modalForm.creditPeriod"
                  persistent-placeholder
                ></v-text-field>

                <v-text-field
                  v-model="editableData.dDueDate"
                  type="text"
                  :label="formatDate(editableData.dDueDate)"
                  outlined
                  disabled
                  color="#000000"
                  dense
                  :placeholder="texts.modalForm.dueDate"
                  persistent-placeholder
                ></v-text-field>
              </v-row>

              <v-row cols="12" md="6">
                <v-text-field
                  type="text"
                  v-model="editableData.dInvoiceExchangeRateProtection"
                  :label="texts.modalForm.exchangeRateProtection"
                  outlined
                  color="#000000"
                  disabled
                  dense
                  :placeholder="texts.modalForm.exchangeRateProtection"
                  persistent-placeholder
                ></v-text-field>

                <v-text-field
                  type="text"
                  v-model="actualCurrency"
                  :label="texts.modalForm.exchangeRateToday"
                  outlined
                  disabled
                  color="#000000"
                  dense
                  :placeholder="texts.modalForm.exchangeRateToday"
                  persistent-placeholder
                ></v-text-field>
              </v-row>

              <v-row cols="12" md="6">
                <v-text-field
                  v-model="editableData.dTotalProtectedUsd"
                  type="text"
                  :label="texts.modalForm.totalProtectedUsd"
                  outlined
                  color="#000000"
                  dense
                  disabled
                  :placeholder="texts.modalForm.totalProtectedUsd"
                  persistent-placeholder
                ></v-text-field>
                <v-text-field
                  v-model="editableData.dTotalUsdToday"
                  type="text"
                  :label="texts.modalForm.totalUsdToday"
                  outlined
                  color="#000000"
                  dense
                  disabled
                  :placeholder="texts.modalForm.totalUsdToday"
                  persistent-placeholder
                ></v-text-field>
              </v-row>

              <v-row cols="12" md="6">
                <v-text-field
                  type="text"
                  v-model="editableData.dCurrencyProfitPercentage"
                  :label="texts.modalForm.currencyProfit"
                  outlined
                  color="#000000"
                  dense
                  disabled
                  :placeholder="texts.modalForm.currencyProfit"
                  persistent-placeholder
                ></v-text-field>

                <v-text-field
                  type="text"
                  v-model="editableData.dCurrencyGain"
                  :label="texts.modalForm.currencyProfitUsd"
                  outlined
                  color="#000000"
                  dense
                  disabled
                  :placeholder="texts.modalForm.currencyProfitUsd"
                  persistent-placeholder
                ></v-text-field>
              </v-row>

              <v-row cols="6" md="6">
                <v-text-field
                  type="text"
                  v-model="editableData.dAmountAdjustment"
                  :label="texts.modalForm.adjust"
                  outlined
                  color="#000000"
                  dense
                  :placeholder="texts.modalForm.adjust"
                  persistent-placeholder
                ></v-text-field>
              </v-row>

              <!-- <v-row>
              <v-checkbox
                v-model="editableData.bIsProtected"
                label="Solicitar factoraje"
                color="#000000"
                dense
              ></v-checkbox>
            </v-row> -->
            </v-col>
          </div>
          <div v-if="isResponsive" class="mt-2">
            <v-btn
              elevation="0"
              @click="updateExchangeRate"
              :loading="bLoading"
              :disabled="!validateForm"
              class="button-primary mon-bold"
              style="width: 100%"
            >
              {{ texts.textTitle }}
            </v-btn>
            <v-btn
              elevation="0"
              @click="closeDialog"
              class="button-secondary mon-regular mt-5 mb-2"
              style="width: 100%"
            >
              {{ texts.modalForm.cancel }}
            </v-btn>
          </div>
          <div v-else class="display-flex align-items-center mt-2">
            <v-btn
              elevation="0"
              @click="closeDialog"
              class="button-cancel mon-regular"
            >
              {{ texts.modalForm.cancel }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              @click="updateExchangeRate"
              :loading="bLoading"
              :disabled="!validateForm"
              class="button-save mon-bold"
            >
              {{ texts.textTitle }}
            </v-btn>
          </div>
        </div>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import moment from "moment";
export default {
  props: {
    texts: {
      type: Object,
      default: () => ""
    },
    exchangeRatesStateForwards: {
      type: Array,
      default: () => []
    },
    formatDate: {
      type: Function,
      default: () => ""
    },
    formatMoney: {
      type: Function,
      default: () => ""
    },
    screenWidth: {
      type: Number,
      default: 0
    },
    dialogEditProfileActive: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function,
      default: () => ""
    },
    editableData: {
      type: Object,
      default: () => ""
    },
    updateExchangeRate: {
      type: Function,
      default: () => ""
    },
    modalCurrency: {
      type: Number,
      default: 0
    },
    actualCurrency: {
      type: Number | String,
      default: 0
    },
    isResponsive: {
      type: Boolean,
      default: false
    },
    bLoading: {
      type: Boolean,
      default: false
    },
    validateForm: {
      type: Function,
      default: () => ""
    },
    openModal: {
      type: Function,
      default: () => ""
    },
    bSelectedAll: {
      type: Boolean,
      default: false
    },
    toggleAllForwards: {
      type: Function,
      default: () => ""
    },
    sendToPayNowForwards: {
      type: Function,
      default: () => ""
    }
  },
  methods: {
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return this.formatDate(result);
    },
    isEditable(data) {
      const today = moment().format("YYYY-MM-DD");
      const date = moment(data).format("YYYY-MM-DD");
      if (date === today) {
        return false;
      }
      return date >= today;
    }
  },
  name: "Forwards"
};
</script>

<style scoped>
.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 20px;
  position: relative;
}

.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 25px;
}

.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.button-save:disabled {
  background: gray !important;
  cursor: not-allowed;
  pointer-events: visible;
}

.button-cancel {
  width: 151px;
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  word-wrap: break-word;
}

.button-save {
  height: 40px !important;
  width: 45% !important;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.textButton {
  text-align: center;
  color: #302e2e;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}

.cancel-text {
  text-align: center;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
  color: #f0be43;
  line-height: 24px;
  word-wrap: break-word;
}

.text-center {
  text-align: center;
  font-family: montserrat-regular;
}

.bold-title {
  color: #151d27;
  font-size: 24px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 36px;
  word-wrap: break-word;
}

.due-date {
  color: #f0be43;
}
</style>
